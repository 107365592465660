
$light-text: #b0b0b0;
$primary: #012e95;
@import url("../src/fonts/stylesheet.css");
body {
  font-family: "Euclid Circular A", sans-serif !important;
  overflow-x: hidden;
}
.logo {
  max-width: 194px;
}
.nmy {
  font-size: 20px;
  font-weight: 700;
}

.text-sm {
  font-size: 85%;
}

a.disabled {
  background: #4D6192;
}
p {
  font-family: "Euclid Circular A", sans-serif !important;
}
.form-control:focus {
  // background-color: #efeff0;
  outline: none;
  box-shadow: none;
}
.accordion-button {
  font-weight: 700;
}
.light-text {
  color: $light-text;
}
.negative-margin-top {
  margin-top: -54px;
}
nav {
  font-weight: 500;
  position: sticky;
  top: 0;
  z-index: 999;
  ul li:hover a {
    color: #012e95;
  }
  .btn {
    height: 42px !important;
    width: 132px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.fs-38 {
  font-size: 38px !important;
}
img {
  max-width: 100%;
  height: auto;
}
.text-primary {
  color: $primary !important;
}
.bg-primary {
  background-color: $primary !important;
}
.btn {
  font-weight: 500;
  font-size: 14px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
}
.p-abs {
  position: absolute;
}
.btn-outline-primary {
  border: 1px solid $primary !important;
  font-weight: 500;
  color: $primary;
}
.btn-outline-primary:hover {
  background-color: #012e95 !important;
  color: white !important;
}
.btn-primary {
  background-color: $primary;
  border-color: $primary;
}

.btn-primary:hover {
  background-color: $primary;
  border-color: $primary;
}

.l {
  border-bottom: 2px solid transparent;
  padding-bottom: 31px;
  transition: all 0.2s ease;
}
.link-active {
  color: #012e95 !important;
  padding-bottom: 31px;
  border-bottom: 2px solid #012e95;
  font-weight: 500;
}

.nav-links {
  gap: 28px;
  font-size: 14px;
  li {
    list-style: none;

    a {
      text-decoration: none;
      color: $light-text;
    }
    a:active {
      color: #012e95;
    }
  }
}
.nav-links-mob {
  gap: 28px;
  font-size: 14px;
}

.btn-grp {
  button {
    min-width: 132px;
    padding: 12px 0;
  }
  gap: 12px;
}
.bg-hero {
  background-image: url("./images/bg.png");
  background-size: cover;
  background-repeat: no-repeat;
}
.accordion-button:not(.collapsed) {
  color: var(--bs-accordion-active-color);
  background-color: white;
  box-shadow: none;
}
.accordion-button:focus {
  z-index: 3;
  border-color: transparent;
  outline: 0;
  box-shadow: none;
}
.accordion-button:after {
  background-image: none;
  content: "+";
  font-size: 24px;
  text-align: center;
}
.text-color {
  color: #555;
}
.carousel-control-next {
  justify-content: end;
}
.carousel-control-prev {
  justify-content: start;
}
.carousel-control-next-icon,
.carousel-control-prev-icon {
  filter: invert(1);
}
.border-bottom-1 {
  border-bottom: 1px solid #dfdfdf;
}
.accordion-button:not(.collapsed):after {
  background-image: none;
  transform: rotate(45deg);
  color: #012e95;
}
.accordion-body {
  padding: var(--bs-accordion-body-padding-y) var(--bs-accordion-body-padding-x);
  font-size: 14px;
  line-height: 28px;
  color: #555;
}
.bg-our {
  background-image: url("./images/our-mission.png");
  background-size: cover;
  background-repeat: no-repeat;
}
.bg-faq {
  background-image: url("./images/faq.png");
  background-size: cover;
  background-repeat: no-repeat;
}
.max-w-hero {
  max-width: 597px;
  h1 {
    font-weight: 700;
  }
}
.hero {
  padding: 50px 0;
}
.pdd {
  padding: 80px 10px;
}
.w-fit {
  width: fit-content;
}
.inflation {
  background-image: url("./images/inflation.png");
  background-size: cover;
  background-repeat: no-repeat;
  label {
    color: #90a3bf;
    font-weight: 400 !important;
    font-size: 14px;
    text-align: left;
  }
  .form-control {
    border-radius: 8px;
    border-right: 0;
  }
  .form-control:placeholder {
    color: #b0b0b0 !important;
    font-weight: 500;
    font-size: 14px;
  }
  .input-group-text {
    background-color: white;
    color: #b0b0b0;
    font-weight: 500 !important;
  }
  .input-group-text-end {
    border-left: 0;
  }
}
label {
  color: #90a3bf;
  font-weight: 400 !important;
  font-size: 14px;
  text-align: left;
}
input.form-control, select.form-select {
  border-radius: 8px;
  height: 50px;
}
.bg-light {
  background-color: #efeff0;
}
.form-control:placeholder {
  color: #b0b0b0 !important;
  font-weight: 500;
  font-size: 14px;
}
.input-group-text {
  background-color: white;
  color: #b0b0b0;
  font-weight: 500 !important;
}
.input-group-texd-end {
  border-left: 0;
}
.fig {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  overflow: hidden;
  img {
    object-fit: cover;
  }
}
.wallet {
  padding: 89px 52px;
  box-shadow: 0px 4px 44px 0px rgba(0, 0, 0, 0.08);
  blockquote {
    text-align: left !important;
    font-size: 24px;
    color: #555;
  }
  .row {
    row-gap: 20px;
  }
}
.emergency {
  .heading {
    margin-bottom: 74px;
  }
  small {
    color: #d1daea;
    font-weight: 300;
  }
}
table tr td {
  background-color: #ffffff !important;
  font-weight: 500;
  white-space: nowrap;
}
table {
  padding: 20px;
  thead {
    tr {
      th {
        background-color: #d7e2fc !important;
        white-space: nowrap;
        font-weight: 500;
      }
    }
  }
  tr {
    th:first-child {
      padding-left: 50px;
    }
    td:first-child {
      padding-left: 50px !important;
    }
    td {
      padding: 0.7rem 0.5rem !important;
    }
  }
  .arrow {
    height: 20px;
    width: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    color: white;
  }
  .down {
    svg {
      transform: rotate(180deg);
    }
    background-color: #d71a00;
  }
  .up {
    background-color: #33c930;
  }
}
.gridbox {
  display: grid;
  padding: 35px;
  grid-template-rows: repeat(2, 1fr);
  grid-template-columns: repeat(2, 1fr);
  gap: 30px;
  small {
    font-weight: 500;
  }
  .shadow {
    box-shadow: 0px 8px 24px 0px rgba(0, 0, 0, 0.06) !important;
  }
  p {
    font-size: 14px;
    margin-top: 12px;
    color: #484848;
  }
}
.shadow {
  box-shadow: 0px 8px 44px 0px rgba(0, 0, 0, 0.06) !important;
}

.flexbox {
  display: flex;
  align-items: center;
  gap: 14px;
  justify-content: center;
  flex-wrap: wrap;
  .cardd {
    background-color: #dae5ff;
    padding-inline: 32px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 480px;
    max-width: 294px;
    box-shadow: 0px 12px 44px 0px rgba(0, 0, 0, 0.12) inset;
    h2 {
      color: #6b7788;
      text-align: center;
      font-feature-settings: "clig" off, "liga" off;
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: 132%; /* 23.76px */
    }
  }
  .top {
    padding-top: 35px;
  }
  .bottom {
    padding-bottom: 35px;
  }
}
.h-after {
  position: relative;
  width: fit-content;
  margin: auto;
}

.h-after:after {
  content: "";
  display: block;
  width: 236px;
  height: 29px;
  position: absolute;
  bottom: 0px;
  right: -33px;
  z-index: -1;
  background: #e8edf6;
}
.code-invite {
  .input-group {
    justify-content: center;
    margin-top: 36px;
    .input-group-text {
      height: 58px !important;
    }
  }
  .form-control {
    max-width: 417px;
    height: 58px;
    border: 1px solid #3a3a3a;
  }
}
.free-e-book {
  background-image: url("./images/bg1.png");
  background-size: cover;
  background-repeat: no-repeat;
  padding: 100px 0;
  .text-s {
    max-width: 385px;
  }
}
.max-w {
  max-width: 600px;
  top: -180%;
  right: 0;
}
.mt-large {
  margin-top: 200px;
}
// --------------------flip card---------------------------
.cardsbox {
  display: flex;
  gap: 32px;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}
.flip-card {
  background-color: transparent;
  width: 281px;
  height: 324px;
  border-radius: 8px;
  perspective: 1000px; /* Remove this if you don't want the 3D effect */
}

/* This container is needed to position the front and back side */
.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.8s;
  transform-style: preserve-3d;
}

/* Do an horizontal flip when you move the mouse over the flip box container */
.flip-card:hover .flip-card-inner {
  transform: rotateY(180deg);
}

/* Position the front and back side */
.flip-card-front,
.flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden; /* Safari */
  backface-visibility: hidden;
}

/* Style the front side (fallback if image is missing) */
.flip-card-front {
  background-color: white;
  color: black;
  border: 2px solid #e5e5e5;
}

/* Style the back side */
.flip-card-back {
  background-color: white;
  color: black;
  transform: rotateY(180deg);
  box-shadow: 0px 8px 44px 0px rgba(0, 0, 0, 0.06);
  padding: 30px;
  h3 {
    color: #040815;
    text-align: center;
    font-feature-settings: "clig" off, "liga" off;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: capitalize;
  }
  p {
    color: #484848;
    text-align: center;
    font-feature-settings: "clig" off, "liga" off;
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: 170%; /* 23.8px */
    margin-top: 12px;
  }
}
.social svg {
  transition: all 0.4s ease;
}
.social svg:hover {
  transform: scale(1.5);
  border-radius: 100%;
  cursor: pointer;
}
.foot-bg {
  background-color: #f6f8fa;
  background-image: url("./images/footer.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: right;
  ul {
    padding: 0;
    margin: 0;
    li {
      list-style: none;
      a {
        text-decoration: none;
        color: #484848;
      }
      a:hover {
        color: #012e95 !important;
      }
    }
  }
}

.col-g {
  column-gap: 120px;
}
.slick-slide {
  padding: 20px 10px;
}
section {
  position: relative;
}

.square {
  position: absolute;
  z-index: -1;
  top: 0;
  animation: float 3s ease-in-out infinite;
  animation-delay: 4s;
}
.left {
  left: 0;
}
.right {
  right: 0;
}
.top50 {
  top: 40%;
}
.bottom {
  bottom: 0;
  top: inherit !important;
}
.bottom-20 {
  bottom: -20%;
  top: inherit !important;
}
.circle-left {
  position: absolute;
  z-index: -2;
  top: 0;
  left: 0;
  animation: float 2s ease-in-out infinite;
  animation-delay: 2s;
}
.circle-right {
  position: absolute;
  z-index: -2;
  top: 0;
  right: 0;
  animation: float 2s ease-in-out infinite;
}
.login-side {
  width: 475px;
  height: 100vh !important;
  background: linear-gradient(180deg, #1f376f 0%, #21469c 100%);
}
.sign-in-max {
  max-width: 464px;
  .tab-buttons {
    display: flex;
    align-items: center;
    gap: 20px;
    justify-content: center;
    margin: 30px 0;
    min-height: 84px;
    small {
      font-size: 0;
    }
  }

  .tab-buttons button {
    border: none;
    border-radius: 50%;
    height: 20px;
    width: 20px;
    margin: 5px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    background: $primary;
    color: white;
    font-weight: bold;
    transition: all 0.4s ease-in-out;
    position: relative;
    svg {
      font-size: 0;
      transition: all 0.4s ease-in-out;
    }
  }
  .text-show {
    font-size: 14px !important;
    transition: all 0.4s ease-in-out;
  }
  #after-line:after {
    content: "";
    display: block;
    height: 2px;
    width: 96px;
    z-index: -1;
    background-color: #012e95;
    position: absolute;
    left: 50px;
    top: 50%;
    transform: translateX(-50%);
  }

  .tab-buttons button.active {
    height: 48px;
    width: 48px;
    svg {
      font-size: 20px;
    }
  }
}
.blog {
  small {
    margin: 12px 0;
    color: #555;
    line-height: 28px;
  }
  strong {
    margin: 12px 0;
    font-size: 22px;
  }
}
.article {
  p {
    font-size: 14px;
  }
  strong {
    font-size: 15px;
  }
}
.Hayekian {
  max-width: 1009px;
  padding-left: 250px !important;
  padding-right: 40px !important;
  margin: auto;
  position: relative;
  h2 {
    text-align: start;
  }
  strong {
    color: #040815;
    font-size: 22px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: capitalize;
    display: block;
    margin-bottom: 20px;
  }
  p {
    color: #555;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: 28px; /* 175% */
    margin-bottom: 20px;
    b {
      font-weight: 700;
      color: black;
    }
  }
  figure {
    width: 300px;
    height: 441px;
    border-radius: 8px;
    overflow: hidden;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: -15%;
    img {
      object-fit: cover;
    }
  }
}
.howitworks {
  background-image: url("./images/hiwbg.png");
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  padding: 0 0 0 70px;
}
.white-box {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  background-color: white;
  padding: 15px;
  img {
    width: 34px;
    height: auto;
  }
}
.trust {
  small {
    opacity: 0.8;
    line-height: 28px !important;
  }
}
.numbering {
  display: flex;
  align-items: center;
  gap: 30px;
  span {
    font-size: 110px;
    color: #f1f5fa;
    font-weight: 700;
    line-height: 120px;
  }
  p {
    color: #040815;
  }
}
.blue-quote {
  small {
    color: #d1daea;
  }
  blockquote {
    font-size: 22px;
  }
}
.pdx {
  padding-inline: 10rem;
}
.text-sec {
  p {
    font-size: 14px;
    opacity: 0.8;
  }
  h6 {
    font-size: 18px;
  }
  h5 {
    font-size: 22px;
    margin-bottom: 20px;
  }
}
.round-numbering {
  font-size: 18px;
  background-color: #efeff0;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 54px;
  min-width: 54px;
  position: relative;
}
.round-numbering > h5 {
  font-weight: 500;
  color: black;
}
.round-numbering:after {
  content: "";
  display: block;
  height: 200px;
  width: 1px;
  position: absolute;
  top: 0;
  z-index: -1;
  background-color: #efeff0;
}
.usenumey {
  article {
    p {
      margin-left: 5rem;
    }
  }
}
.content-none:after {
  content: none !important;
}
.reservebox .revrserboxin h2 {
  font-size: 18px;
  font-weight: 600;
  line-height: 42px;
  text-align: center;
}
.workboxblue small {
  font-size: 14px;
  font-weight: 600;
  line-height: 170%;
  color: #dbdfee;
  margin: 19px 0;
}
.brdr {
  border: 1px solid #717171;
  border-radius: 0 8px 8px 0;
  border-left: 0;
  margin-left: -2px;
}
.reservebox .revrserboxin {
  padding-top: 42px;
  padding-bottom: 40px;
}
.btnn {
  width: 50px !important;
}
.opac {
  opacity: 0.8 !important;
}
.nav-active .mobile-nav {
  left: 0 !important;
}
.mobile-nav {
  position: fixed;
  left: -100%;
  top: 66px;
  background-color: #02267bd4;
  padding: 20px;
  width: 250px;
  height: 100dvh;
  backdrop-filter: blur(4px);
  transition: all 0.4s ease-in-out;
  li {
    padding: 20px 0;
    border-bottom: 1px solid white;
    list-style: none;
    text-align: center;
    a {
      text-decoration: none;

      color: white;
      cursor: pointer;
    }
  }
}

// css start from here 
.authentication-block {
  position: relative;
  display: flex;
  align-items: center;
  height: 100vh;
  width: 100%;
}
.authentication-block .sidebar {
  width: 470px;
  background: linear-gradient(180deg, #1F376F 0%, #21469C 100%);
  height: 100vh;
  overflow: hidden;
  padding: 60px 60px 40px 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}
.authentication-block .sidebar .ilustrationbar {
  padding: 30px 0;
}
.authentication-block .sidebar .sidebar-bottomside {
  text-align: center;
}
.authentication-block .sidebar .sidebar-bottomside h5 {
  color: #FFF;
  text-align: center;
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin: 0 0 12px 0;
}
.authentication-block .sidebar .sidebar-bottomside p {
  margin: 0 0 0 0;
  color: #C4CEE4;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 28.16px;
}
.authentication-block .Auth-bar {
  width: calc(100% - 470px);
  padding: 40px;
  height: 100vh;
  overflow: auto;

}
.centeredbox{
  align-items: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.authentication-block .Auth-bar form {
  max-width: 434px;
  margin: 0 auto;
  width: 100%;
}
.authentication-block .Auth-bar .formtitle {
  text-align: center;
  margin-bottom: 24px;
}
.authentication-block .Auth-bar .formtitle h3 {
  color: #012E95;
  text-align: center;
  font-family: Euclid Circular A;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 40px; 
  letter-spacing: -0.72px;
  text-transform: uppercase;
  margin-bottom: 5px;
}
.authentication-block .Auth-bar form .formtitle p {
  margin: 0;
  color: #555;
  font-family: Euclid Circular A;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; 
  letter-spacing: -0.32px;
}
.authentication-block .Auth-bar form label.form-label {
  color: #90A3BF;
  font-family: Euclid Circular A;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
  letter-spacing: -0.32px;
  margin-bottom: 10px;
}
.authentication-block .Auth-bar form .form-control {
  border-radius: 8px;
  border: 1.5px solid #F0F0F0;
  padding: 16px 16px;
  height: auto;
  color: #000000;
  font-family: Euclid Circular A;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
  letter-spacing: -0.28px;
}
.authentication-block .Auth-bar form .form-control:placeholder{
  color: #B0B0B0;
}
.authentication-block .Auth-bar form .input-group {
  position: relative;
}
.authentication-block .Auth-bar form .input-group .btneye {
  position: absolute;
  right: 0;
  margin: 0;
  border: none;
  outline: none;
  box-shadow: none;
}
.authentication-block .Auth-bar .forgotbar {
  color: #012E95;
  font-family: Euclid Circular A;
  font-size: 17px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
  letter-spacing: -0.28px;
  text-decoration: none;
  margin-left: 5px;
}
.text-render-bottom h6 {
  color: #6F6F6F;
  font-family: Euclid Circular A;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 19.6px */
  letter-spacing: -0.28px;
}
.step-form {
  margin-top: 30px;
  justify-content: space-between;
  margin-bottom: 20px;
  align-items: center !important;
}
.step-form .ant-steps-item-container .ant-steps-item-icon {
  font-size: 0 !important;
}
.step-form .ant-steps-item.ant-steps-item-active .ant-steps-item-icon {
  width: 50px !important;
  height: 50px !important;
  font-size: 20px !important;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
.step-form .ant-steps-item-container {
  text-align: center;
}
.step-form .ant-steps-item-container .ant-steps-item-icon {
  margin: 0 0 10px 0 !important;
  background: #012E95;
  border-color: #012E95;
}
.step-form .ant-steps-item-container  .ant-steps-item-content {display: block;text-align: center;}
.step-form .ant-steps-item-container .ant-steps-item-content .ant-steps-item-title {
  padding: 0;
  opacity: 0;
  text-align: center;
  font-family: Euclid Circular A;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.step-form .ant-steps-item-container .ant-steps-item-content .ant-steps-item-title:after {
  top: -27px;
  left: 100px;
  width: 130px !important;
}
.step-form .ant-steps-item {flex: 0 0 25% !important;max-width: 25%;text-align: center;padding: 0 !important;}
.step-form .ant-steps-item.ant-steps-item-active .ant-steps-item-title {
  opacity: 1 !important;
  color: #012E95 !important;
}
.authentication-block .Auth-bar form .ant-btn {
    width: 100%;
    border-radius: 8px;
    background: #012E95;
    box-shadow: 4px 8px 24px 0px rgba(36, 107, 253, 0.08);
    padding: 13px 24px;
    height: auto;
    color: #FFF;
    text-align: center;
    font-family: Euclid Circular A;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 21px */
    letter-spacing: -0.28px;
}
.verify-bar {
  position: relative;
}
.verify-bar ul {
  padding: 0 0 0 30px;
  margin: 0;
  list-style: none;
}
.verify-bar ul li {
  border-radius: 6.649px;
  border: 1px solid #D7DCE4;
  background: #FFF;
  padding: 12px 16px;
  margin-bottom: 12px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 15px;
  position: relative;
}
.verify-bar ul li:before {
  content: '';
  width: 24px;
  height: 24px;
  background: #012E95;
  border-radius: 50px;
  position: absolute;
  left: -40px;
  top: 50%;
  transform: translate(0px, -50%);
  z-index: 1;
}
.verify-bar ul li .iconbar svg {
  color: #012E95;
  font-size: 24px;
}
.verify-bar ul li .verify-content h5 {
  color: #192038;
  font-family: Euclid Circular A;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 26.39px;
  margin: 0;
  cursor: pointer;
}
.verify-bar ul li .verify-content p {
  color: #8F9BB3;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 26.39px;
  margin-bottom: 20px;
}
.verify-bar ul li .verify-content .btn {
  font-size: 12px;
  padding: 7px 20px !important;
  height: auto !important;
}
.verify-bar ul li:after {
  content: '';
  width: 2px;
  height: 100%;
  left: -30px;
  position: absolute;
  top: 0;
  background: #D7DCE4;
}
.auth-layout {
  position: relative;
}
.header {
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 999;
  background: #ffffff;
  display: none;
}
.auth-layout .sidebar .sidebarblock .sidebar-menubar .bottombar ul li a.menulink {
  transition: 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  column-gap: 10px;
  color: #90A3BF;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration: none;
  border-radius: 12px;
  padding: 10px 0 !important;
}
.auth-layout .sidebar {
  position: fixed;
  left: 0;
  top: 0;
  height: 100vh;
  overflow: hidden;
  width: 280px;
  background: linear-gradient(180deg, #1F376F 0%, #21469C 100%);
}
.auth-layout .sidebar .sidebarblock {
  height: 100%;
}
.auth-layout .sidebar .sidebarblock .logobar {
  padding: 20px;
  background: #ffffff;
}
.auth-layout .sidebar .sidebarblock .tophead {
  background: #21418D;
  padding: 23px 30px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  column-gap: 15px;
}
.auth-layout .sidebar .sidebarblock .tophead img {
  width: 43px;
  height: 43px;
  object-fit: cover;
  border-radius: 50px;
}
.auth-layout .sidebar .sidebarblock .tophead .userdetail h5 {
  color: #FFF;
  font-family: Euclid Circular A;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin:  0 0 0 0;
}
.auth-layout .sidebar .sidebarblock .tophead .userdetail .btnprofile {
  color: #90A3BF;
  font-family: Euclid Circular A;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.auth-layout .sidebar .sidebarblock .sidebar-menubar {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: calc(100% - 250px);
  overflow: auto;
}
.auth-layout .sidebar .sidebarblock .sidebar-menubar .bottombar ul li a.btnprofile {
  padding: 12px 0 !important;
}
.auth-layout .sidebar .sidebarblock .sidebar-menubar ul {
  padding: 25px 14px;
  margin: 0;
  list-style: none;
}
.auth-layout .sidebar .sidebarblock .sidebar-menubar ul li a.btnprofile {
  padding: 14px 16px;
  transition: .3s ease;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  column-gap: 10px;
  color: #90A3BF;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration: none;
  border-radius: 12px;
}
.auth-layout .sidebar .sidebarblock .sidebar-menubar ul li a.btnprofile.active {
  // background: #4D6192;
  background: #1d247c;
  color: white;
}


.auth-layout .wrapper {
  margin-left: 280px;
  margin-top: 0px;
  position: relative;
}

.auth-layout .wrapper .header {
  background: #FFF;
  box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.02);
  height: 90px;
}
.header .headerblock {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 30px;
  box-shadow: 0px 1px 10px rgb(0 0 0 / 8%);
}
.header .headerblock ul {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  column-gap: 18px;
  list-style: none;
  padding: 0;
  margin: 0;
}

.header .headerblock ul .btn.btnadding {
  border-radius: 6px;
  border: 1px solid #012E95;
  background: #FCFCFC;
  box-shadow: 4px 8px 24px 0px rgba(36, 107, 253, 0.08);
}
.header .headerblock ul .btn.btnadding span.btnplus {
  font-size: 20px;
  color: #203e84;
}
.header .headerblock ul .btn.btnadding span.card {
  font-size: 20px;
  color: #868D94;
}
.content-wrapper {
  height: 100%;
  position: relative;
}
.content-wrapper .breadcrumbar {
  padding: 34px 34px 72px 34px;
  background: #F3F4F6;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.content-wrapper .breadcrumbar h4 {
  color: #192038;
  font-family: Euclid Circular A;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 29.344px; /* 122.267% */
}
.content-wrapper .wallet-bar {
  border-radius: 12px;
  background: #FFF;
  box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.02);
  margin: 34px;
  padding: 34px 34px;
  margin-top: -50px;
  position: relative;
}
.wallet-lg{
  width: 100px;
  height: 30px;
}
.saved-card {
  padding: 20px;
  border-radius: 8px;
  background: #012E95;
  box-shadow: 0px 5px 30px 0px rgba(0, 0, 0, 0.05);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
}
.saved-card .topbar h4 {
  color: #FFF;
  font-family: Euclid Circular A;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 0;
}
.saved-card .topbar h5 {
  color: #90A3BF;
  font-family: Euclid Circular A;
  font-size: 11px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 0;
}
.saved-card .bottombar h2 {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 12px;
  color: #FFF;
  font-family: Euclid Circular A;
  font-size: 28px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.saved-card .bottombar h3 {
  margin: 0;
  color: #FFF;
  text-align: left;
  font-family: Euclid Circular A;
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.trasection-list-block {
  border-radius: 8px;
  border: 1px solid #DDD;
  background: #FFF;
}
.trasection-list-block h4 {
  padding: 18px 24px;
  border-bottom: 1px solid #DDDDDD;
}
.trasection-list-block .transection-tabs {
  padding: 24px;
}
.trasection-list-block .transection-tabs .nav-tabs {
  column-gap: 12px;
  border: none;
}
.trasection-list-block .transection-tabs .nav-tabs li .nav-link {
  border: none;
  border-radius: 16px;
  background: rgb(1 46 149 / 10%);
  padding: 6px 24px;
  color: #868D94;
  text-align: center;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Euclid Circular A;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 170%; /* 20.4px */
}
.trasection-list-block .transection-tabs .nav-tabs li .nav-link.active {
  background: #012E95;
  color: #ffffff;
}
.btnheaderbar .btnadding {
    border-radius: 6px;
    border: 1px solid #012E95 !important;
    background: #FCFCFC !important;
    box-shadow: 4px 8px 24px 0px rgba(36, 107, 253, 0.08);
    padding: 10px 14px;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2px;
    color: #040815;
    text-align: center;
    font-family: Euclid Circular A;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
    letter-spacing: -0.28px;
}
.alltransection-table .listrow {
    padding-bottom: 12px;
    padding-top: 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #dddddd;
}
.alltransection-table .listrow .list-left h4 {
    padding: 0;
    margin: 0;
    border: none;
    color: #192038;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Euclid Circular A;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 170%; /* 23.8px */
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
}
.alltransection-table .listrow .list-left h4 button {
    color: #012E95;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Euclid Circular A;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-decoration-line: underline;
    border: none;
    background-color: transparent;
    padding: 0;
}
.alltransection-table .listrow .list-left p {
    margin: 0;
    color: #868D94;
    text-align: left;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Euclid Circular A;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 170%; /* 20.4px */
}
.alltransection-table .listrow .list-right h5 {
    margin: 0;
    text-align: right;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Euclid Circular A;
    font-size: 18px !important;
    font-style: normal;
    font-weight: 500;
    line-height: 170%; /* 23.8px */
}
.textred {
    color: #F9395C;
}
.textgreen{
  color: #4FCC4D;
}
.alltransection-table .listrow .list-right p {
    margin: 0;
    color: #868D94;
    text-align: right;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Euclid Circular A;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 170%; /* 20.4px */
}
.alltransection-table:last-child .listrow {
    border: none;
}
.alltransection-table .listrow .text-md {
  font-size: 12px;
}
.New-modal_ui .modal-header {
  display: flex;
  align-items: center;
  justify-content: center;
  border: none !important;
  color: #012E95;
  text-align: center;
  font-family: Euclid Circular A;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 29.344px;
}
.New-modal_ui .modal-header .btn-close {
  margin: 0;
  padding: 0;
  position: absolute;
  right: 20px;
}
.New-modal_ui .modal-body {
  border: none !important;
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.New-modal_ui .modal-body .shadowboxes {
  border-radius: 8px;
  border: 1px solid #F2F2F2;
  background: #FFF;
  padding: 14px 16px;
}
.New-modal_ui .modal-body .shadowboxes ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
.New-modal_ui .modal-body .shadowboxes ul li {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 5px;
}
.New-modal_ui .modal-body .shadowboxes ul li .srno {
  margin: 0;
  color: var(--Basic-600, #8F9BB3);
  font-family: Euclid Circular A;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}
.New-modal_ui .modal-body .shadowboxes ul li .nm {
  color: var(--Basic-700, #2E3A59);
  text-align: right;
  font-family: Euclid Circular A;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  display: flex;
  gap: 5px;
  align-items: center;
  justify-content: flex-end;
  margin: 0;
}
.New-modal_ui .modal-body .shadowboxes ul li:last-child {margin-bottom: 0px;}
.NewuiModal .modal-header {
  display: block;
  text-align: center;
  border: none;
  position: relative;
}
.NewuiModal .modal-header button.btn-close {
  position: absolute;
  padding: 0;
  top: 30%;
  right: 30px;
  transform: translate(0px, -50%);
  margin: 0;
}
.getappbtns {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0px;
  flex-direction: column;
}
.getappbtns p {
  width: 100%;
}
.NewuiModal .modal-body {
  padding: 0 20px 20px 20px;
}
.NewuiModal .modal-body .shadowboxes {
  border-radius: 8px;
  border: 1px solid #F2F2F2;
  background: #FFF;
  padding: 14px 18px;
  margin-bottom: 20px;
}
.NewuiModal .modal-body .shadowboxes ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
.NewuiModal .modal-body .shadowboxes ul h5.srno {
  margin: 0;
  color: var(--Basic-700, #2E3A59);
  font-family: Euclid Circular A;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}
.NewuiModal .modal-body .btncopy {
  width: 100%;
  border-radius: 8px;
  background: #012E95;
  box-shadow: 4px 8px 24px 0px rgba(36, 107, 253, 0.08);
  color: #FFF;
  text-align: center;
  font-family: Euclid Circular A;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 21px */
  letter-spacing: -0.28px;
  padding: 13px 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}
.NewuiModal .modal-body .btncopy svg {
  font-size: 20px;
}
.btnsignout {
  border-radius: 6px;
  border: 1px solid #DF5151;
  background: #F2F3F5;
  padding: 10px 18px;
  color: #DF5151;
  text-align: right;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Euclid Circular A;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 14.795px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
}
.btnsignout svg {
  font-size: 20px;
}
.profile_bar .editbar {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.authentication-block .Auth-bar form .input-group .btn {
  min-height: 53.59px;
  border: none !important;
}
.header .headerblock ul a {
  text-decoration: none;
}
.useredit-picture .imagebar {
    position: relative;
}
.useredit-picture .imagebar img {
    width: 100px;
    height: 100px;
    object-fit: cover;
    border-radius: 50px;
}
.useredit-picture .imagebar .btnupload {
    position: absolute;
    width: 34px;
    height: 34px;
    border-radius: 50px;
    background: #204393;
    bottom: 0;
    right: 0;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    color: #ffffff;
    font-size: 16px;
    line-height: 1;
}
.useredit-picture .imagebar .btnupload input[type="file"] {
    width: 100%;
    height: 100%;
    opacity: 0;
    border-radius: 50%;
    z-index: 2;
    cursor: pointer;
    position: absolute;
    top: 0;
    left: 0;
}
.useredit-picture .userdetail h4 {
    color: #012E95;
    font-family: Euclid Circular A;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 29.344px; /* 146.72% */
    margin: 0 0 5px 0;
}

.useredit-picture .userdetail p {
    color: #192038;
    font-family: Euclid Circular A;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 0;
}
.btnblueoutline {
    border-radius: 6px;
    border: 1px solid #012E95;
    background: #FFF;
    padding: 10px 18px;
    color: #012E95;
    text-align: center;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Euclid Circular A;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 14.795px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
}
.btnblueoutline svg {
    font-size: 16px;
}
.profile-usefullcontent {
  margin-top: 30px;
}

.usefull-boxes .usefull-head svg {
  font-size: 30px;
  color: #012E95;
}
.usefull-boxes .usefull-head .detail h4 , .usefull-boxes .usefull-head .detail p {
  margin: 0;
}
.usefull-boxes .usefull-head .detail h4 {
  color: #012E95;
  text-align: left;
  font-family: Euclid Circular A;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 29.344px; /* 146.72% */
  margin: 0 0 3px 0 !important;
}
.usefull-boxes .usefull-head .detail p {
  color: #8F9BB3;
  font-family: Euclid Circular A;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; 
}
.usefull-boxes .usefull-head .arrow {
  position: absolute;
  right: 0;
}
.usefull-boxes .usefull-head .arrow button {
  padding: 0;
  margin: 0;
  border: none;
  background: transparent;
}
.usefull-boxes ul {
  padding: 0;
  list-style: none;
}
.usefull-boxes ul.contactdetail {
  border-bottom: 1px solid #D7DCE4;
  padding-bottom: 20px;
  margin-top: 20px;
}
.usefull-boxes ul.contactdetail li.add1 {
  color: var(--Basic-700, #2E3A59);
  font-family: Euclid Circular A;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; 
}
.usefull-boxes ul.contactdetail li.add-2 {
  color: var(--Basic-700, #2E3A59);
  font-family: Euclid Circular A;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}
.usefull-boxes ul.contactdetail li.date {
  color: var(--Basic-600, #8F9BB3);
  font-family: Euclid Circular A;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}
.usefull-boxes ul.contactdetail li.status {
  color: #4FCC4D;
  font-family: Euclid Circular A;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}
.usefull-boxes ul li {
  color: var(--Basic-700, #2E3A59);
  font-family: Euclid Circular A;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}
.usefull-boxes ul.source-fund-list {
  height: 160px;
  overflow-x: hidden;
  overflow-y: scroll;
  margin-top: 20px;
}
.usefull-boxes ul.source-fund-list li.cfr {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  padding-bottom: 19px;
  margin-bottom: 18px;
  border-bottom: 1px solid #D7DCE4;
}
.usefull-boxes .btnfix {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 2;
}
.usefull-boxes .btnfix button.fundadd {
  border-radius: 0px 0px 6px 6px;
  background: #012E95;
  width: 100%;
  padding: 12px;
  color: #FFF;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Euclid Circular A;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 17.594px; /* 146.613% */
}
.EditProfile_tabs {
  border: none !important;
  display: grid;
  grid-template-columns: 50% 50%;
}
.EditProfile_tabs li .nav-link {width: 100%;border-radius: 0px !important;background: #E6EAF4 !important;border: none !important;color: #868D94;font-feature-settings: 'clig' off, 'liga' off;font-family: Euclid Circular A;font-size: 14px;font-style: normal;font-weight: 400;line-height: 17.594px; /* 125.668% */padding: 13px !important;}

.EditProfile_tabs li .nav-link.active {
  background: #012E95 !important;
  color: #ffffff !important;
}
.numeyicon {
  width: 14px;
  height: 24px;
}
.main_balance h5,  .main_balance h6 {
  margin: 0;
}
.main_balance h5 {
  text-align: center;
  font-family: Euclid Circular A;
  font-size: 34px;
  font-style: normal;
  font-weight: 500;
  line-height: 41.92px; 
}
.main_balance h6 {
  font-family: Euclid Circular A;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  min-width: 70px;
}
.select-buy-numey-dropdown ul.countries-list {
  list-style: none;
  padding: 0;
  margin: 0;
  overflow: visible;
  position: relative;
}
.select-buy-numey-dropdown ul.countries-list li.cfr {
  border-radius: 12px;
  background: #F5F5F5;
  padding: 18px 24px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  color: var(--Basic-700, #2E3A59);
  font-family: Euclid Circular A;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; 
  position: relative;
  z-index: 2;
}
.select-buy-numey-dropdown ul.countries-list .dropdownbar {
  transition: 0.3s ease;
  transform: translate(0px, -100%);
  transform-origin: top;
  position: absolute;
  width: 100%;
  left: 0;
  z-index: 2;
  top: 59px;
  height: 120px;
  overflow: auto;
}
.select-buy-numey-dropdown ul.countries-list .dropdownbar li.cfr {
  border-radius: 0;
  cursor: pointer;
}
.select-buy-numey-dropdown.open ul.countries-list .dropdownbar {transform: translate(0px, 0px);}

.select-buy-numey-dropdown ul.countries-list .dropdownbar li.cfr {
  position: relative;
  z-index: 1;
}
.Notebar {
  margin: 20px 0;
  border-radius: 34px;
  background: rgb(1 46 149 / 10%);
  padding: 9px 14px;
}
.Notebar p {
  color: var(--Basic-600, #8F9BB3);
  text-align: center;
  font-family: Euclid Circular A;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  margin: 0;
}
.main_balance input {
  appearance: none;
  border: none;
  outline: none;
  display: inline;
  font-family: Euclid Circular A;
  font-size: 52px;
  font-style: normal;
  font-weight: 500;
  line-height: 41.92px;
  max-width: 240px;
}
.main_balance input.form-control:focus {
  background-color: none;
}
.main_balance input:placeholder {
    opacity: .5;
}
.sendnumeybar {
  max-width: 343px;
  margin: 0 auto;
}
.sendnumeybar .sendnumey-titlebar {
  text-align: center;
  margin-bottom: 40px;
}
.sendnumeybar .sendnumey-titlebar h4 {
  color: #012E95;
  text-align: center;
  font-family: Euclid Circular A;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 29.344px;
  margin: 0 0 4px 0;
}
.sendnumeybar .sendnumey-titlebar p {
  margin: 0;
  color: #192038;
  text-align: center;
  font-family: Euclid Circular A;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20.96px; 
}
.sendnumeybarstep2 {
  max-width: 360px;
  margin: 0 auto;
}
.sendnumeybarstep2 button.backbtn {
  position: absolute;
  top: 20px;
  left: 20px;
  border: none;
  background: transparent;
  font-size: 30px;
  color: #214291;
}
.sendnumeybarstep2 .sendnumey {
  text-align: center;
}
.sendnumeybarstep2 .sendnumey .sendto {
  color: #868D94;
  font-family: Euclid Circular A;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 10px;
}
input:-webkit-outer-spin-button,
input:-webkit-inner-spin-button {
 -webkit-appearance: none;
 margin: 0;
}
/* Firefox */
input[type=number] {
 -moz-appearance: textfield;
}

.NewEx-Ui .authentication-block {
  height: auto;
}
.NewEx-Ui .authentication-block .newex-col-none {
  margin: 0;
  width: 100%;
}

.NewEx-Ui .authentication-block .newex-col-none .newex-pad-none {
  padding: 20px !important;
  margin: 0 !important;
}
.NewEx-Ui .authentication-block .newex-col-none .newex-pad-none .link-secondary , .NewEx-Ui .authentication-block .newex-col-none .newex-pad-none .btn-secondary {
  background: #022e95;
  padding: 10px;
  width: 100%;
  display: block;
  border-radius: 10px;
  text-align: center;
  color: #ffffff !important;
}
.signup-funding-ui .newex-pad-none {
  max-width: 434px;
  margin: 0 auto;
  width: 100%;
}
.signup-funding-ui .newex-pad-none h4.text-center.mb-4 {
  color: #012E95;
  text-align: center;
  font-family: Euclid Circular A;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 40px;
  letter-spacing: -0.72px;
  text-transform: uppercase;
  margin-bottom: 5px;
}
.signup-funding-ui .newex-pad-none .mt-4.d-grid.gap-2.my-4.mx-2 {
  margin: 0 !important;
}
.signup-funding-ui .newex-pad-none .mt-4.d-grid.gap-2.my-4.mx-2 .btn-block {
  background: #012e95;
  width: 100%;
  margin-top: 10px;
}
.signup-funding-ui .newex-pad-none .link-secondary {
  background: transparent;
  padding: 10px;
  width: 100%;
  display: block;
  text-align: center;
  border-radius: 10px;
  color: #8a929a !important;
  border: 1px solid #8a929a;
  margin-top: 10px;
}
.mobilebottombar-widget{
  display: none;
}
.btndanger {
  background: red !important;
}
.btnblue {
  background: #012e95 !important;
}
.mobenable{
  display: none;
}

// ---------------------responsive-----------------------------------
@media (max-width: 1399px) {
  .reservebox .revrserboxin {
    padding-top: 22px;
    padding-bottom: 40px;
  }
}
@media only screen and (max-width: 1200px) {
  nav .btn {
    width: 114px;
  }
  .wallet blockquote {
    font-size: 20px;
  }
  .foot-bg {
    background-size: cover;
  }
  .p-abs {
    position: static;
  }
  .mt-large {
    margin-top: 0px;
  }
  .Hayekian figure {
    position: static;
    transform: none;
    margin-top: 20px;
  }
  .Hayekian {
    padding-left: 20px !important;
  }
  .free-e-book {
    padding: 20px 0;
  }
}
@media only screen and (max-width: 1024px) {
  nav .btn {
    width: 100px;
    font-size: 12px;
  }
  .pdx {
    padding-inline: 8rem;
  }
}
@media only screen and (max-width: 991px) {
  .authentication-block .sidebar {
    display: none;
}
a {}

.mobile-nav li a {
    color: #ffffff !important;
}

.step-form {
    flex-direction: row !important;
    align-items: flex-start !important;
}

.step-form .ant-steps-item-container {
    display: block !important;
}

.step-form .ant-steps-item.ant-steps-item-active .ant-steps-item-icon {
    float: none;
}

.step-form .ant-steps-item-container .ant-steps-item-content {
    min-height: auto !important;
}

.step-form .ant-steps-item-container .ant-steps-item-icon {
    float: none !important;
}

.step-form .ant-steps-item-container .ant-steps-item-tail {
    display: none !important;
}
.authentication-block .Auth-bar {
    width: 100%;
    background: #ffffff;
    padding: 20px;
}
.mobile-nav li a {
  color: #ffffff !important;
}
.authentication-block .Auth-bar .mobenable {
    display: block;
    text-align: center;
    margin-bottom: 50px;
}
  .workboxblue {
    padding: 0;
    margin: 30px 0 !important;
  }

  .brdr {
    border-left: 1px solid #717171;
    margin: 0;
    border-radius: 8px;
  }
  .gridbox {
    display: grid;
    padding: 35px;
    grid-template-rows: repeat(4, 1fr);
    grid-template-columns: repeat(1, 1fr);
  }
  .circle-left {
    max-width: 70%;
  }
  .circle-right {
    max-width: 70%;
  }
  .fs-lg-4 {
    font-size: 1rem !important;
  }
  .square {
    max-width: 70%;
  }
  .col-g {
    column-gap: 80px;
  }
  .h-after:after {
    right: 9px;
  }
  .pdd {
    padding: 60px 10px;
  }
  .fs-38 {
    font-size: 32px !important;
  }
  .pdx {
    padding-inline: 4rem;
  }
}
@media (max-width: 767px){
  .mobile-sidebar {
    width: 280px !important;
}
.auth-layout .sidebar {
  display: none;
}
.mobile-sidebar .auth-layout .sidebar {
  display: block;
}
.btnbar {
  background: #3b6fc9;
  border: none;
  width: 40px;
  height: 40px;
  border-radius: 6px;
  color: #ffffff;
}
.mobile-sidebar button.btn-close {
    z-index: 999;
    margin: 0;
}

.auth-layout .sidebar .sidebarblock .logobar {
    text-align: left;
}

.auth-layout .sidebar .sidebarblock .logobar img {
    width: 170px;
}
  .header{
    display: block;
  }
  .auth-layout .sidebar .sidebarblock .sidebar-menubar .bottombar ul li a.btnprofile {
    padding: 12px 0 !important;
}

.content-wrapper .breadcrumbar {
  padding: 35px 15px 70px 15px;
}
.btnsignout {
  padding: 10px;
}


.profile_bar .editbar {flex-direction: column;gap: 20px;align-items: flex-start;}

.useredit-picture .imagebar img {
    width: 50px;
    height: 50px;
}
.mobilebottombar-widget ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}
.mobilebottombar-widget ul li .btnprofile.active {
  color: #4582ec !important;
  font-weight: bold;
}
.sendnumeybar .sendnumey-titlebar {
  text-align: center;
  margin-bottom: 30px;
}
.mobilebottombar-widget ul li .btnprofile svg {
  font-size: 18px;
}
.trasection-list-block .transection-tabs {
    padding: 10px;
}
.trasection-list-block .transection-tabs .nav-tabs {
  gap: 12px;
}
.trasection-list-block h4 {
  padding: 10px 12px;
  border-bottom: 1px solid #DDDDDD;
  font-size: 16px;
}
.mobilebottombar-widget ul li .btnprofile {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: #B5C4DB;
  text-align: center;
  font-family: Euclid Circular A;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration: none;
  gap: 8px;
}
.useredit-picture .userdetail h4 {
    font-size: 16px;
    margin: 0 0 0px 0;
}

.useredit-picture .userdetail p {
    font-size: 12px;
}

.useredit-picture {
    gap: 10px;
}
.auth-layout .wrapper {
  margin: 80px 0 0 0 !important;
  padding-bottom: 0px;
}

.content-wrapper .wallet-bar {padding: 15px !important;margin: -50px 15px 15px  15px!important;}
.mobilebottombar-widget{
  display: none;
}
.header .headerblock {
    padding: 15px 15px;
    gap: 10px;
}

.header .headerblock .logobar {
    flex: 0 0 150px;
}

.header .headerblock ul {
    column-gap: 10px;
}

.header .headerblock ul .btn svg.numeyicon {
    width: 7px;
    height: auto;
}
}
@media only screen and (max-width: 768px) {
  .brdr {
    border: none;
    padding: 0 !important;
  }
  .reservebox .revrserboxin {
    padding: 0 !important;
  }
  .circle-left {
    max-width: 50%;
  }
  .brdr {
    border: none;
  }
  .circle-right {
    max-width: 50%;
  }
  .square {
    max-width: 50%;
  }
  .col-g {
    column-gap: 40px;
  }
  .pdd {
    padding: 40px 10px;
  }
  .fs-38 {
    font-size: 28px !important;
  }
  .pdx {
    padding-inline: 3rem;
  }
  .wallet {
    padding: 78px 22px;
  }
  .howitworks {
    padding: 20px;
  }
  .gridbox {
    padding: 15px;
  }
}
@media only screen and (max-width: 574px) {
  .circle-left {
    max-width: 30%;
  }
  .circle-right {
    max-width: 30%;
  }
  .square {
    max-width: 30%;
  }
  .wallet blockquote {
    font-size: 14px;
  }
  .emergency .heading {
    margin-bottom: 45px;
  }
  .pdd {
    padding: 30px 10px;
  }
  .fs-38 {
    font-size: 26px !important;
  }
  .pdx {
    padding-inline: 0rem;
  }
  .wallet {
    padding: 31px 11px;
  }
  .gridbox {
    padding: 0px;
  }
}
@keyframes float {
  0% {
    transform: translatey(0px);
  }
  50% {
    transform: translatey(-20px);
  }
  100% {
    transform: translatey(0px);
  }
}

.form-text {
  font-size: .775em;
}

.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #012e95;
  border-color: #012e95;
}
.page-link {
  color: $primary;
}

table tr td:first-child {
  padding-left: 15px !important;
}

table tr th:first-child {
  padding-left: 15px;
}